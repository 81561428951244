import zPayFinishAccessModal from "../zPayFinishAccessModal";
export default {
  name: "zPayPaymentCompletedCard",
  components: {
    zPayFinishAccessModal
  },
  props: {
    cards: {
      type: Array
    },
    isAuthenticated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openFinishAccess: false
    };
  }
};
