import zPayExpenseStatementDialog from "@/components/zPayExpenseStatementDialog";
//import {zPayExpenseStatementDialog} from "@zoox-framework/zoox-components";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "zPayPaymentReceiptCard",
  components: {
    zPayExpenseStatementDialog
  },
  props: {
    charge: {
      type: Object,
      default: {}
    },
    company: {
      type: Object,
      default: {}
    },
    order: {
      type: Object,
      default: {}
    },
    debits: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currency: "BRL",
      isAuthenticated: false,
      expenseDialog: false
    };
  },
  computed: {
    ...mapState(["currentCharge", "paymentValue"]),
    checkinDate() {
      return moment
        .utc(this.order.metadata.checkinDate)
        .endOf("day")
        .format(this.$t("GLOBAL.DATE_FORMAT"));
    },
    checkoutDate() {
      return moment
        .utc(this.order.metadata.checkoutDate)
        .endOf("day")
        .format(this.$t("GLOBAL.DATE_FORMAT"));
    }
  }
};
