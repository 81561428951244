export default {
  name: "zPayFinishAccessModal",
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    mounted() {
      this.$emit("finish");
    }
  }
};
