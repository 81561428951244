import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";
import OrderService from "@/services/OrderService";
import PaymentService from "@/services/PaymentService";
import zPayPaymentCompletedCard from "@/components/zPayPaymentCompletedCard";
import zPayPaymentReceiptCard from "@/components/zPayPaymentReceiptCard";
import zPayExpenseStatementDialog from "@/components/zPayExpenseStatementDialog";
import { mapState } from "vuex";

// import {
//   zPayExpenseStatementDialog
// } from "@zoox-framework/zoox-components";
export default {
  name: "Checkout",
  components: {
    zPayPaymentCompletedCard,
    zPayExpenseStatementDialog,
    zPayPaymentReceiptCard
  },
  data() {
    return {
      companyService: new CompanyService(),
      personService: new PersonService(),
      orderService: new OrderService(),
      paymentService: new PaymentService(),
      dialogExpenseVisible: false,
      totalValue: 0,
      charge: {},
      order: {},
      checkoutDate: "",
      checkinDate: "",
      company: {},
      debits: [],
      isAuthenticated: false,
      loading: false,
      prevRoute: null,
      pushNotificationDialog: false
    };
  },
  async beforeCreate() {
    const paymentService = new PaymentService();
    const orderService = new OrderService();
    const token = paymentService.getChargeToken();
    if (token && token != "null") {
      const charge = await orderService.getChargeByToken(token);
      if (
        charge.status != "paid" &&
        charge.status != "processing" &&
        charge.status != "authorized" &&
        charge.status != "failed" &&
        charge.status != "cancelled" &&
        charge.status != "with_error"
      ) {
        this.$router.back();
      }
    } else {
      this.$router.back();
    }
  },
  async created() {
    await this.prepareReceipt();
    this.isAuthenticated = this.personService.isAuthenticated();
    const viewedNotification = localStorage.getItem("viewedNotification");

    if (viewedNotification != null) {
      this.pushNotificationDialog = false;
      await this.acceptReceiveMessage();
    } else {
      this.pushNotificationDialog = false;
    }
  },
  computed: {
    cards() {
      return [
        {
          title: this.$t(
            "PAYMENT_SOLICITATION.PAYMENT_RECEIPT.USER_DATA_BUTTON"
          ),
          action: "UserInfo",
          icon: "far fa-user",
          color: "#FF8F28"
        },
        {
          title: this.$t(
            "PAYMENT_SOLICITATION.PAYMENT_RECEIPT.PAYMENT_SOLICITATION_BUTTON"
          ),
          action: "PaymentSolicitation",
          icon: "fas fa-credit-card",
          color: "#53A9D8"
        },
        {
          title: this.$t(
            "PAYMENT_SOLICITATION.PAYMENT_RECEIPT.PAYMENT_HISTORY_BUTTON"
          ),
          action: "PaymentHistory",
          icon: "fas fa-list-ul",
          color: "#239465"
        }
      ];
    },
    ...mapState(["pushNotificationService"])
  },
  methods: {
    finish() {
      localStorage.clear();
    },
    payments() {
      this.$router.push("/payment/solicitations");
    },
    history() {
      this.$router.push("/payment/history");
    },
    user() {
      this.$router.push("/user/profile");
    },
    closePushNotificationModal() {
      this.pushNotificationDialog = false;
    },
    async subscribeUserToReceive() {
      const clientName = "centralpay-mobile";
      const vapIds = await this.companyService.getVapIds(
        this.company.id,
        clientName
      );

      const subscription = await this.pushNotificationService.subscribe(
        vapIds.publicKey
      );

      await this.paymentService.upsertSubscription(
        {
          id: this.currentCharge.id,
          type: clientName
        },
        subscription
      );
    },
    async acceptReceiveMessage() {
      localStorage.setItem("viewedNotification", true);
      const token = await this.paymentService.getChargeToken();
      if (token) {
        this.currentCharge = await this.orderService.getChargeByToken(token);
      }

      const ret = await this.pushNotificationService.requestPermissionNotification();
      if (ret) {
        this.pushNotificationDialog = false;
        await this.subscribeUserToReceive();
      } else {
        this.pushNotificationDialog = false;
      }
    },
    viewStatement(obj) {
      this.value = obj.amount;
      this.debits = obj.debits;
      this.dialogExpenseVisible = true;
    },
    async checkIfHaveAPaidCharge() {},
    async prepareReceipt() {
      this.loading = true;
      const token = this.paymentService.getChargeToken();
      this.company = await this.companyService.getCurrentCompany();
      this.charge = await this.orderService.getChargeByToken(token);
      this.order = await this.orderService.getOrderById(this.charge.order.id);
      let itemsIds = [];
      this.charge.items.forEach(function(item) {
        itemsIds.push(item.id);
      });
      const items = await this.orderService.getBulkItems(itemsIds);
      items.forEach(item => {
        const percentLeft = item.percentLeft || 100;
        const itemValue = (item.amount * item.quantity * percentLeft) / 100;
        this.debits.push({
          name: item.description,
          price: itemValue
        });
      });
      if (
        this.charge.status == "paid" ||
        this.charge.status == "processing" ||
        this.charge.status == "authorized" ||
        this.charge.status == "failed" ||
        this.charge.status == "cancelled" ||
        this.charge.status == "with_error"
      ) {
        this.loading = false;
      }
    }
  }
};
